<template>
    <History 
        :related_object="task.id" 
        injectContainer
        filterPrefix="task"
        modelLabel="tasks.TaskModel"
        :injectContainerSelector="bodyWrap" />
</template>

<script>
import History from '@apps/History/index.vue'
export default {
    name: "TaskHistory",
    props: {
        task: {
            type: Object,
            required: true
        },
        bodyWrap: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        History
    }
}
</script>